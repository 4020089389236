import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export function initWebsocket() {
    const config = useRuntimeConfig();
    const { $api } = useNuxtApp();
    const { localeProperties } = useI18n();

    window.Pusher = Pusher;

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: config.public.ws.key,
        cluster: config.public.ws.cluster,
        wsHost: config.public.ws.wsHost,
        wsPort: config.public.ws.wsPort,
        wssPort: config.public.ws.wssPort,
        forceTLS: config.public.ws.forceTLS,
        enabledTransports: ['ws', 'wss'],
        authorizer: (channel, options) => {
            return {
                authorize: (socketId, callback) => {
                    $api.post(`${localeProperties.value.protocol}://${localeProperties.value.api}/v1/broadcasting/auth`, {
                        body: {
                            socket_id: socketId,
                            channel_name: channel.name
                        },
                        onResponse: ({ response }) => {
                            callback(response.ok ? null : true, response._data);
                        }
                    });
                }
            };
        },
    });
}
